import { userLocale } from "./i18n/supported-locales";

const FAQ_QUESTION_HRI = "FAQ_QUESTION";
const FAQ_ANSWER_HRI = "FAQ_ANSWER";
const LIST_ITEM = "LIST_ITEM";
const TEASER_BLOCK_HRI = "PAGE_TEASER";
const ID_PREFIX = "ID";
const CONTACT_US_COMPONENT = "Contact Us";
const MENU_ITEMS_NAME_DEFAULT = "Products";
const MENU_ITEMS_NAME_HON_ACU = "Services";
const COMPONENT_MAPPINGS = {};
COMPONENT_MAPPINGS["Dealer Website Certification"] = "DWC_WEBSITE_CERTIFICATION";
COMPONENT_MAPPINGS["Dealer Website Compliance"] = "DWC_WEBSITE_CERTIFICATION";
COMPONENT_MAPPINGS["{Dealer} Website Certification"] = "DWC_WEBSITE_CERTIFICATION";
COMPONENT_MAPPINGS["SmartLeads"] = "DWC_SMARTLEADS";
COMPONENT_MAPPINGS["Dealer CRM Certification"] = "DWC_CRM_CERTIFICATION";
COMPONENT_MAPPINGS["{Dealer} CRM Certification"] = "DWC_CRM_CERTIFICATION";
COMPONENT_MAPPINGS["Shopper Contact"] = "DWC_SHOPPER_CONTACT";
COMPONENT_MAPPINGS["SmartAnalytics (Dashboard)"] = "DWC_SMART_ANALYTICS";
COMPONENT_MAPPINGS["Contact Us"] = "DWC_CONTACT_US";
COMPONENT_MAPPINGS["Concierge Elite Service"] = "DWC_CONCIERGE_ELITE";
COMPONENT_MAPPINGS["Website & Digital Advertising Options"] = "DWC_WEBSITE_CERTIFICATION";
COMPONENT_MAPPINGS["Enterprise Services"] = "DWC_ENTERPRISE_SERVICES";
COMPONENT_MAPPINGS["Marketplace Services"] = "DWC_MARKETPLACE_SERVICES";
COMPONENT_MAPPINGS["Digital Retailing Solution Services"] = "DWC_DRS_SERVICES";
COMPONENT_MAPPINGS["Field Support"] = "DWC_FIELD_SUPPORT";

export default {
    faqQuestionHri() {
        return FAQ_QUESTION_HRI;
    },
    faqAnswerHri() {
        return FAQ_ANSWER_HRI;
    },
    getLanguageParamValue() {
        return userLocale();
    },
    prepareHomePageCmsDataPayload (oemId, headerMenuData, hasContactUs) {
        let params = {}
        params.oemId = oemId;
        let pagesHriArr = [];
        let productsServicesItems = headerMenuData
            .filter(item => item.name == MENU_ITEMS_NAME_DEFAULT || item.name == MENU_ITEMS_NAME_HON_ACU)
            .map(item => item.filteredChildren);
        if (productsServicesItems.length > 0) {
            pagesHriArr = productsServicesItems[0]
                .map(item => COMPONENT_MAPPINGS[item.name])
                .filter(item => item != null && item.length > 0);
        }
        if (hasContactUs) {
            pagesHriArr.push(COMPONENT_MAPPINGS[CONTACT_US_COMPONENT]);
        }
        params.language = userLocale();
        params.pagesHri = pagesHriArr.toString();
        params.blocksHri = TEASER_BLOCK_HRI;
        return params;
    },
    getEditableFaq(data) {
        if (!data || data.length < 1) return [];
        let officialFaq = this.getOfficialEditableFaq(data);
        let draftFaq = this.getDraftsEditableFaq(data);
        draftFaq.forEach(draft => {
            let original = officialFaq.filter(o => o.id == draft.contentBlockId);
            if (original.length > 0) {
                original[0].order = draft.order;
            } else {
                officialFaq.push(draft);
            }
        })
        return officialFaq.sort((a, b) => a.order - b.order);
    },
    getDraftsEditableFaq(data) {
        if (!data || data.length < 1) return [];
        let listItems = data[0].drafts
            .filter(item => item.humanReadableId == FAQ_QUESTION_HRI);
        let toReturn = []
        listItems.
            map(item => {
                let record = {};
                record.id = item.id;
                record.order = item.blockOrder;
                record.contentBlockId = item.contentBlockId;
                record.image = null;
                record.title = item;
                record.description = data[0].contentBlocks.filter(c => c.humanReadableId == FAQ_ANSWER_HRI && c.parent == item.contentBlockId)[0];
                if (!record.description) {
                    record.description= data[0].drafts.filter(c => c.humanReadableId == FAQ_ANSWER_HRI && c.parent == item.id)[0];
                }
                toReturn.push(record);
            })
        return toReturn;
    },
    getOfficialEditableFaq(data) {
        if (!data || data.length < 1) return [];
        let listItems = data[0].contentBlocks
            .filter(item => item.humanReadableId == FAQ_QUESTION_HRI);
        let toReturn = []
        listItems.
            map(item => {
                let record = {};
                record.id = item.id;
                record.order = item.blockOrder;
                record.contentBlockId = null;
                record.image = null;
                record.title = item;
                record.description = data[0].contentBlocks.filter(c => c.humanReadableId == FAQ_ANSWER_HRI && c.parent == item.id)[0];
                toReturn.push(record);
            })
        return toReturn;
    },
    getFaqContent(pageCmsData) {
        if (!pageCmsData || pageCmsData.length < 1) return [];
        let faq = [];
        let answers = new Map(
                        pageCmsData[0].contentBlocks
                        .filter(item => item.parent != null)
                        .map(obj => {
                          return [obj.parent, obj.content];
                        }));
        pageCmsData[0].contentBlocks
            .filter(item => item.humanReadableId == FAQ_QUESTION_HRI)
            .map(obj => {
                let record = {};
                record.faqId = ID_PREFIX + obj.id;
                record.faqToggle = obj.content;
                record.faqBody = answers.get(obj.id);
                faq.push(record);
            })
        return faq;
    },
    getDraftObjectToSave(pageId, pageCmsData, blockHri, newContent) {
       let pageData = pageCmsData.filter(item => item.id == pageId);
       if (pageData.length != 1) {
           window.console.log("No pageCmsData for pageId: " + pageId);
           return null;
       } else {
            let draftData = pageData[0].drafts.filter(c => c.humanReadableId == blockHri);
            let draft = draftData.length == 1 ? draftData[0] : {};
            draft.content = newContent;
            draft.pageId = pageId;
            if (!draft.id) {
                draft.language = this.getLanguageParamValue().toUpperCase();
                let blockData = pageData[0].contentBlocks.filter(c => c.humanReadableId == blockHri);
                draft.contentBlockId = (blockData.length == 1 && blockData[0].language == draft.language) ? blockData[0].id : null;
                draft.humanReadableId = blockData.length == 1 ? blockData[0].humanReadableId : null;
                draft.type = blockData.length == 1 ? blockData[0].type : null;
                draft.blockOrder = blockData.length == 1 ? blockData[0].blockOrder : null;
                draft.parent = blockData.length == 1 ? blockData[0].parent : null;
                draft.imageFormat = blockData.length == 1 && blockData[0].imageFormat != null ? blockData[0].imageFormat.id : null;
                pageData[0].drafts.push(draft);
            }
            return draft;
       }
    },
    createContentDraft(pageId, pageCmsData, blockHri, content, order, parent, type, imageFormat) {
        let draft = {};
        draft.pageId = pageId;
        draft.language = this.getLanguageParamValue().toUpperCase();
        draft.humanReadableId = blockHri;
        draft.type = type;
        draft.blockOrder = order;
        draft.parent = parent;
        draft.content = content;
        draft.imageFormat = imageFormat != null ? imageFormat.id : null;
        pageCmsData[0].drafts.push(draft);
        return draft
    },
    createEmptyDraftFromExisting(newHri, hriToCopyFrom, pageData) {
        let draftData = pageData[0].contentBlocks.filter(c => c.humanReadableId == hriToCopyFrom)[0];
        let draft = {}
        if (draftData) {
            draft.content = "";
            draft.status = draftData.status;
            draft.blockOrder = draftData.blockOrder;
            draft.pageId = draftData.pageId;
            draft.language = draftData.language;
            draft.humanReadableId = newHri;
            draft.type = draftData.type;
            draft.parent = draftData.parent;
            draft.imageFormat = draftData.imageFormat != null ? draftData.imageFormat.id : null;
            pageData[0].drafts.push(draft);
        }
        return draft;
    },
    getDraftObjectToSaveById(pageId, pageCmsData, blockId, newContent, newStatus, newOrder) {
       let pageData = pageCmsData.filter(item => item.id == pageId);
       if (pageData.length != 1) {
           window.console.log("No pageCmsData for pageId: " + pageId);
           return null;
       } else {
            let draftData = pageData[0].drafts.filter(c => c.contentBlockId == blockId || c.id == blockId);
            let draft = draftData.length == 1 ? draftData[0] : {};
            if (newContent != null) {
                draft.content = newContent;
            }
            if (newStatus != null) {
                draft.status = newStatus;
            }
            if (newOrder != null) {
                draft.blockOrder = newOrder;
            }
            draft.pageId = pageId;
            if (!draft.id) {
                draft.language = this.getLanguageParamValue().toUpperCase();
                let blockData = pageData[0].contentBlocks.filter(c => c.id == blockId);
                draft.contentBlockId = (blockData.length == 1 && blockData[0].language == draft.language) ? blockData[0].id : null;
                draft.humanReadableId = blockData.length == 1 ? blockData[0].humanReadableId : null;
                draft.type = blockData.length == 1 ? blockData[0].type : null;
                draft.blockOrder = draft.blockOrder == null && blockData.length == 1 ? blockData[0].blockOrder : draft.blockOrder;
                draft.parent = blockData.length == 1 ? blockData[0].parent : null;
                draft.content = draft.content == null && blockData.length == 1 ? blockData[0].content : draft.content;
                draft.imageFormat = blockData.length == 1 && blockData[0].imageFormat != null ? blockData[0].imageFormat.id : null;

                pageData[0].drafts.push(draft);
            }
            if (draft.imageFormat && draft.imageFormat.id) {
                draft.imageFormat = draft.imageFormat.id;
            }
            return draft;
       }
    },
    getPageId(component, pageCmsData) {
        if (!pageCmsData || pageCmsData.length < 1) return "";
        let pageHri = COMPONENT_MAPPINGS[component];
        if (!pageHri) {
            window.console.log("No mapping for component: " + component);
        } else {
            let pageData = pageCmsData.filter(item => item.humanReadableId == pageHri);
            return pageData.length == 1 ? pageData[0].id : null;
        }
    },
    getBlockContent(component, pageCmsData, blockHri) {
        if (!pageCmsData || pageCmsData.length < 1) return "";
        let pageHri = COMPONENT_MAPPINGS[component];
        if (!pageHri) {
            window.console.log("No mapping for component: " + component);
        } else {
            return this.getBlockContentForPage(pageHri, pageCmsData, blockHri);
        }
    },
    getListsData(data) {
        if (!data || data.length < 1) return [];
        let officialProvidersData = this.getOfficialListsData(data);
        let draftProvidersData = this.getDraftsListsData(data);
        draftProvidersData.forEach(draft => {
            let original = officialProvidersData.filter(o => o.id == draft.contentBlockId);
            if (original.length > 0) {
                original[0].order = draft.order;
            } else {
                officialProvidersData.push(draft);
            }
        })
        return officialProvidersData.sort((a, b) => a.order - b.order);
    },
    getOfficialListsData(data) {
        if (!data || data.length < 1) return [];
        let listItems = data[0].contentBlocks
            .filter(item => item.humanReadableId == 'LIST_ITEM');
        let providersData = []
        listItems.
            map(item => {
                let record = {};
                record.id = item.id;
                record.order = item.blockOrder;
                record.contentBlockId = null;
                record.image = data[0].contentBlocks.filter(c => c.humanReadableId == 'LIST_ITEM_IMAGE' && c.parent == item.id)[0];
                record.title = data[0].contentBlocks.filter(c => c.humanReadableId == 'LIST_ITEM_HEADER' && c.parent == item.id)[0];
                record.description = data[0].contentBlocks.filter(c => c.humanReadableId == 'LIST_ITEM_TEXT' && c.parent == item.id)[0];
                providersData.push(record);
            })
        return providersData;
    },
    getDraftsListsData(data) {
        if (!data || data.length < 1) return [];
        let listItems = data[0].drafts
            .filter(item => item.humanReadableId == 'LIST_ITEM');
        let providersData = []
        listItems.
            map(item => {
                let record = {};
                record.id = item.id;
                record.order = item.blockOrder;
                record.contentBlockId = item.contentBlockId;
                record.image = data[0].contentBlocks.filter(c => c.humanReadableId == 'LIST_ITEM_IMAGE' && c.parent == item.contentBlockId)[0];
                if (!record.image) {
                    record.image = data[0].drafts.filter(c => c.humanReadableId == 'LIST_ITEM_IMAGE' && c.parent == item.id)[0];
                }
                record.title = data[0].contentBlocks.filter(c => c.humanReadableId == 'LIST_ITEM_HEADER' && c.parent == item.contentBlockId)[0];
                if (!record.title) {
                    record.title = data[0].drafts.filter(c => c.humanReadableId == 'LIST_ITEM_HEADER' && c.parent == item.id)[0];
                }
                record.description = data[0].contentBlocks.filter(c => c.humanReadableId == 'LIST_ITEM_TEXT' && c.parent == item.contentBlockId)[0];
                if (!record.description) {
                    record.description= data[0].drafts.filter(c => c.humanReadableId == 'LIST_ITEM_TEXT' && c.parent == item.id)[0];
                }
                providersData.push(record);
            })
        return providersData;
    },
    getBlockContentForPage(pageHri, pageCmsData, blockHri) {
       if (!pageCmsData || pageCmsData.length < 1) return "";
       let pageData = pageCmsData.filter(item => item.humanReadableId == pageHri);
       if (pageData.length != 1) {
           window.console.log("No pageCmsData for pageHri: " + pageHri);
       } else {
           let blockData = pageData[0].contentBlocks.filter(c => c.humanReadableId == blockHri);
           if (blockData.length != 1 ) {
               window.console.log("No block data for pageHri: " + pageHri + " and blockHri: " + blockHri);
           } else {
               return blockData[0].content;
           }
       }
    },
    getDraftBlockContentForPage(component, pageCmsData, blockHri) {
       if (!pageCmsData || pageCmsData.length < 1) return null;
       let pageHri = COMPONENT_MAPPINGS[component];
       let pageData = pageCmsData.filter(item => item.humanReadableId == pageHri);
       let draft = this.getDraftForPage(pageHri, pageCmsData, blockHri);
       return draft != null ? draft.content : null;
    },
    getDraftForPage(pageHri, pageCmsData, blockHri) {
       if (!pageCmsData || pageCmsData.length < 1) return "";
       let pageData = pageCmsData.filter(item => item.humanReadableId == pageHri);
       if (pageData.length != 1) {
           return null;
       } else {
           let blockData = pageData[0].drafts.filter(c => c.humanReadableId == blockHri);
           if (blockData.length != 1 ) {
               return null;
           } else {
               return blockData[0];
           }
       }
    },
    getDraftForPageById(pageHri, pageCmsData, blockId) {
       if (!pageCmsData || pageCmsData.length < 1) return "";
       let pageData = pageCmsData.filter(item => item.humanReadableId == pageHri);
       if (pageData.length != 1) {
           return null;
       } else {
           let blockData = pageData[0].drafts.filter(c => c.contentBlockId == blockId);
           if (blockData.length != 1 ) {
               blockData = pageData[0].drafts.filter(c => c.id == blockId);
               if (blockData.length != 1 ) {
                    return null;
               }
           }
           return blockData[0];
       }
    },
    getBlockContentForPageById(pageHri, pageCmsData, blockId) {
       if (!pageCmsData || pageCmsData.length < 1) return "";
       let pageData = pageCmsData.filter(item => item.humanReadableId == pageHri);
       if (pageData.length != 1) {
           window.console.log("No pageCmsData for pageHri: " + pageHri);
       } else {
           let blockData = pageData[0].contentBlocks.filter(c => c.id == blockId);
           if (blockData.length != 1 ) {
               window.console.log("No block data for pageHri: " + pageHri + " and blockId: " + blockId);
           } else {
               return blockData[0].content;
           }
       }
    }
}