<template>
    <div :class="[isOpen ? 'open'  : 'close']" class="modal fade modal_template opacity-1" role="dialog">
        <div class="covering_div"></div>
        <div class="modal-dialog no_transform">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <div style="pointer-events: none" class="info_heading">
                        <h4>{{ $t("app.CHECK_CONTACT_INFO") }}</h4>
                    </div>
                </div>
                <div class="modal-body">
                    <div   class="modal_form">
                      <div class="modal_heading">
                        <h4>{{ $t("app.CONTACT_DEALER_WEBSITE_CERTIFICATION") }}</h4>
                      </div>
                        <form>
                            <div class="row">
                                <div class="col-xs-12   manage_form_inputs" v-bind:class="{'col-sm-5': disableInputFields, 'col-sm-7': !disableInputFields}">
                                  <form-field id="1"  error-class="form_error" :errors="errors">
                                    <label class="form_title" style="display: block;">{{ $t("app.AUTHORIZED_NAME") }}: </label>
                                    <label class="view_labels" v-if="disableInputFields"> {{ profileData.authorizedPerson }}</label>
                                    <input v-else class="form-control" type="text" v-model="profileData.authorizedPerson"  :disabled="!!disableInputFields" maxlength="250">
                                  </form-field>
                                </div>
                                <div class="col-xs-12  manage_form_inputs" v-bind:class="{'col-sm-5': disableInputFields, 'col-sm-7': !disableInputFields}">
                                  <form-field id="2"   error-class="form_error" :errors="errors" v-bind:style="[!disableInputFields ? {'padding-bottom': '10px'} : {}]">
                                    <label class="form_title" style="display: block;">{{ $t("app.AUTHORIZED_PHONE") }}: </label>
                                    <label class="view_labels" v-if="disableInputFields"> {{ profileData.authorizedPhone }}</label>
                                    <input v-else class="form-control" type="text"  v-model="profileData.authorizedPhone" :disabled="!!disableInputFields" maxlength="250">
                                  </form-field>
                                </div>
                                <div class="col-xs-12   manage_form_inputs" v-bind:class="{'col-sm-5': disableInputFields, 'col-sm-7': !disableInputFields}"
                                     v-bind:style="[!disableInputFields ? {} : {'float': 'none'}]" >
                                  <form-field id="3"   error-class="form_error" :errors="errors">
                                    <label class="form_title" style="display: block;">{{ $t("app.AUTHORIZED_EMAIL") }}: </label>
                                    <label class="view_labels" v-if="disableInputFields">{{ profileData.authorizedEmail }} </label>
                                    <input v-else class="form-control" type="text" v-model="profileData.authorizedEmail"  :disabled="!!disableInputFields" maxlength="250">
                                  </form-field>
                                </div>
                              <div class="col-xs-12 row" >
                                <div class="col-sm-5" v-if="disableInputFields">
                                  <button @click="onClickEditContactDetails" class="contact_submit edit"  type="button">{{ $t("app.CONTACT_EDIT") }}</button>
                                </div>
                                <div class="col-xs-12 " v-bind:class="{'col-sm-5': disableInputFields, 'col-sm-6': !disableInputFields}">
                                  <button @click="saveProfileContact" class="contact_submit" type="button">{{ $t("app.CONFIRM") }}</button>
                                </div>
                              </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { customMessages } from '@/config/VaidationMessages';
import { bus } from '@/main';
import ValidationUtil from "@/util/ValidationUtil";
import {mapState} from "vuex";
import * as moment from "moment-timezone";

export default {
  created() {
      bus.$on('openDWCContactModal', (data) => {
        const me = this;
        me.profileData = data;
        me.isOpen = !me.isOpen;
        $("body").addClass("modal-open");
      })
    },
    computed: {
      ...mapState('dwc', ['UserDetails', 'UserProfileErrors'])
    },
    props: [],
    name: 'modal_template',
    data() {
        return {
          isOpen: false,
          disableInputFields: true,
          profileData: {
            dealerWebsite: null,
            dealershipCode: null,
            title: null,
            contactDetailsName: null,
            emailAddress: null,
            phoneNumber: null,
            authorizedPerson: null,
            authorizedEmail: null,
            authorizedPhone: null,
            showBillingDetails: null,
            lastUpdatedDateForDealerContact: null
          },
          errors: [],
          errorsNew: []
        }
    },
    methods: {
      saveProfileContact: function () {
        const me = this;
        if ((!this.UserProfileErrors && this.disableInputFields) && !this.checkDWCPContactDetailsModalIsFinalStep()) {
          me.onClickConfirmContactDetails();
          return;
        }
        if (me.checkFormValidation()) {
          var contactDetails = {
            email: this.profileData.emailAddress,
            name: this.profileData.contactDetailsName,
            phone: this.profileData.phoneNumber,
            title: this.profileData.title
          };
          if (this.checkDWCPContactDetailsModalIsFinalStep()) {
            me.profileData.lastUpdatedDateForDealerContact = moment(new Date()).format('YYYY-MM-DD');
          }
          var dwcpEnrollmentContactDetailsData = {
            authorizedPhone: this.profileData.authorizedPhone,
            authorizedPerson: this.profileData.authorizedPerson,
            authorizedEmail: this.profileData.authorizedEmail,
            dealerWebsite: this.profileData.dealerWebsite,
            lastUpdatedDateForDealerContact: this.profileData.lastUpdatedDateForDealerContact
          };
          const profileData = {
            contactDetails: contactDetails,
            dwcpEnrollmentContactDetailsData: dwcpEnrollmentContactDetailsData,
          };

          this.$store.dispatch('dwc/saveProfileContactDetails', profileData)
              .then(() => {
                if (!me.UserProfileErrors) {
                  me.showSuccessMessage = true;
                  me.disableInputFields = true;
                  $("body").removeClass("modal-open");
                  setTimeout(function () {
                    me.showSuccessMessage = false;
                  }, 5000);
                  me.onClickConfirmContactDetails();
                } else {
                  me.hasGeneralError = true;
                  me.showErrorMessage = true;
                  setTimeout(function () {
                    me.showErrorMessage = false;
                  }, 5000);
                }
              })
        }
      },
      checkFormValidation() {
        this.errors = [];
        this.hasGeneralError = false;
        if (!this.profileData.authorizedPerson) {
          this.errors.push({
            isActive: true,
            id: 1,
            error: this.$t("app.REQUIRED_FIELD")
          });
        }
        if (!this.profileData.authorizedEmail) {
          this.errors.push({
            isActive: true,
            id: 3,
            error: this.$t("app.REQUIRED_FIELD")
          });
        }  else if (!ValidationUtil.isValidEmail(this.profileData.authorizedEmail.trim())) {
          this.errors.push({
            isActive: true,
            id: 3,
            error: this.$t("app.INVALID_EMAIL")
          });
        }
        if (!this.profileData.authorizedPhone) {
          this.errors.push({
            isActive: true,
            id: 2,
            error: this.$t("app.REQUIRED_FIELD")
          });
        } else if (!ValidationUtil.isValidPhone(this.profileData.authorizedPhone.trim())) {
          this.errors.push({
            isActive: true,
            id: 2,
            error: this.$t("app.INVALID_PHONE")
          });
        }
        return this.errors.length === 0;
      },
        showError(id, errors) {
            let isActive = false;
            if (errors.length > 0) {
                let errorData = (errors.filter(err => err.id === id));
                isActive = errorData.length > 0 && errorData[0].isActive
            }
            return isActive;
        },
        showErrorText(id, errors) {
            let error = '';
            if (errors.length > 0) {
                let errorData = (errors.filter(err => err.id === id));
                error = errorData.length > 0 && errorData[0].error
            }
            return (!error) ? '' : error;
        },
        onClickEditContactDetails() {
          this.disableInputFields = !this.disableInputFields;
        },
        onClickConfirmContactDetails() {
          let me = this;
          me.isOpen = !me.isOpen;
          if (me.profileData.showBillingDetails) {
            bus.$emit('openBillingModal', me.profileData);
          } else {
            window.localStorage.setItem('profileContactModalCancelled', null);
            $("body").removeClass("modal-open");
          }
        },
        checkDWCPContactDetailsModalIsFinalStep() {
          let me = this;
        return !me.profileData.showBillingDetails;
      }
    }
}
</script>
<style scoped>
.view_labels{
  font-weight: 400;
}
.manage_form_inputs {
  padding-bottom: 25px;
}
.modal_heading {
  padding-bottom: 31px;
}

.modal_form h4 {
  font-size: 18px;
  color: #157c8e;
  padding-right: 72px;
}
 .modal_form .contact_submit {
  height: 31px;
  width: 173px;
  border: 1px solid #8c7b6b;
}
.modal_form .edit {
  color: #8c7b6b;
  background-color: #ffffff;
}

.modal_form .form_error .form-control {
    border: 1px solid red !important;
    box-shadow: none;
}
.modal_template .modal-dialog {
  width: 85%;
  max-width: calc(95vh - 220px);
}

.form_title {
  color: #666666d1;
  font-style: normal;
  font-weight: 400;
  padding-right: 10px;
}
.modal_form .required_val {
  color: red !important;
}
.modal_form .required_validation {
  color: red !important;
  font-weight: 600;
  font-size: 14px;
  margin: 3px 0;
  position: absolute;
  padding-left: 0px;
}
.modal_form .form_error .form-control {
  border: 1px solid red !important;
  box-shadow: none;
}

.modal_heading {
  padding-bottom: 31px;
}

.modal_form h4 {
  font-size: 18px;
  color: #157c8e;
}
.modal_form .contact_submit {
  height: 31px;
  width: 173px;
  font-family: 'Lato', sans-serif;
  border: 1px solid #8c7b6b;
}
.modal_form .edit {
  color: #8c7b6b;
  background-color: #ffffff;
}
.modal_form .required_val {
  color: red !important;
}
.modal_form .required_validation {
  color: red !important;
  font-weight: 600;
  font-size: 14px;
  margin: 3px 0;
  position: absolute;
  padding-left: 0px;
}
.modal_form .form_error .form-control {
  border: 1px solid red !important;
  box-shadow: none;
}

.modal_template .modal-dialog {
  min-width: 50%;
  max-width: calc(95vh - 220px);
}

.form_title{
  font-weight: bold;
}
.view_labels{
  font-weight: 400;
}
.form_title, .view_labels{
  color: #666666d1;
  font-style: normal;
}
.modal_form .required_val {
  color: red !important;
}
.modal_form .form_error .form-control {
  border: 1px solid red !important;
  box-shadow: none;
}
.modal_template .info_heading h4::after{
  width: 30%;
}
.modal_template .info_heading h4::before {
  width: 76%;
}

.modal_form {
  position: relative;
  left: 13%;
}
</style>
