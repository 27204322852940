import Vue from 'vue';
import {resetState} from '../modules';

export const mutations = {
    setDWCData(state, dwcData) {
        state.dwcData = dwcData;
    },
    login_success(state, payload) {
        state.loginSuccess = true;
        state.userName = payload.userName;
        state.userPass = payload.userPass;
        state.tokenData = payload.tokenData;
    },
    login_error(state, payload) {
        state.loginError = true;
        state.userName = payload.userName;
    },
    setPriceComparisonData(state, payload) {
        state.PriceComparisonData = payload;
    },
    updateLoader(state, payload) {
        state.isLoading = payload;
    },
    setHeaderMenuData(state, payload) {
        state.headerMenuData = payload;
    },
    saveEnrolmentData(state, payload) {
        state.enrolmentData = payload;
    },
    updateAPIErrors(state, payload) {
        state.APIError = payload;
    },
    updateOtherDealersData(state, payload) {
        state.OtherDealersData = payload;
    },
    updateInvalidDWCPUrlMessage(state, payload) {
        state.InvalidDWCPUrlMessage = payload;
    },
    updateSessionExpired(state, payload) {
        state.SessionExpired = payload;
    },
    updateLoginAPIErrors(state, payload) {
        state.AccountLocked = payload;
    },
    updateUserDetails(state, payload) {
        state.UserDetails = payload;
    },
    updateProductsData(state, payload) {
        state.Products = payload;
    },
    updateAPICallFlag(state, payload) {
        state.API_Call_Flag = payload;
    },
    updateForgotPass(state, payload) {
        state.forgotPass = payload;
    },
    resetStateByName(state, payload) {
        if (payload.stateName === 'selectedDealerBudgetDetails') {
            if (state[payload.stateName]) {
                state[payload.stateName].budget = payload.value.budget;
                state[payload.stateName].balance = payload.value.balance;
                state[payload.stateName].budgetForCurrentMonth = payload.value.budgetForCurrentMonth;
            }
        } else {
            state[payload.stateName] = payload.value;
        }
    },
    updateAllAddonDetails(state, payload) {
        state.addonDetails = payload;
    },
    updateAllOEMs(state, payload) {
        state.oems = payload;
    },
    updateAllOfferProviders(state, payload) {
        state.offerProviders = payload;
    },
    updateUsersToImpersonate(state, payload) {
        state.usersToImpersonate = payload;
    },
    updateSelectedDealerProgramDetails(state, payload) {
        state.dealerProductDetails = payload;
    },
    updateLeadSalesDetails(state, payload) {
        state.leadSalesDetails = payload;
    },
    updateSelectedDealerBudgetDetails(state, payload) {
        state.selectedDealerBudgetDetails = payload;
    },
    updateSelectedDealerLeadSettingsDetails(state, payload) {
        state.selectedDealerLeadSettingsDetails = payload;
    },
    updateAdjustCoverageLeadSettingsDetails(state, payload) {
        state.adjustCoverageLeadSettingsDetails = payload;
    },
    getRadiusCity(state, payload) {
        state.radiusCityDetails = payload;
    },
    getCityZipList(state, payload) {
        state.cityZipListDetails = payload;
    },
    saveDealersLeadCoverageDetails(state, payload) {
        state.saveDealersLeadCoverageDetails = payload
    },
    getRadiusAPIError(state, payload) {
        state.APIError = payload;
    },
    updatePreferencesLeadSettingsDetails(state, payload) {
        state.preferencesLeadSettingsDetails = payload;
    },
    updateLeadPreferencesNewDestination(state, payload) {
        state.preferencesLeadSettingsDetails.leadDestinations = payload;
    },
    updateVehicleManagement(state, payload) {
        state.VehicleManagementDetails = payload;
    },
    updateSuccessAPIResponse(state, payload) {
        state.updateSuccessAPIResp = payload;
    },
    //Manage Profile Code Starts Here - Assign Data
    updateUserProfileDetails(state, payload) {
        state.UserProfileDetails = payload;
    },
    updateUserProfileErrors(state, payload) {
        state.UserProfileErrors = payload;
    },
    updateBillingProfileDetails(state, payload) {
        state.BillingProfileDetails = payload;
    },
    //Manage Profile Code Ends Here
    updateUserProfileContactDetails(state, payload) {
        state.UserProfileContactDetails = payload;
    },
    updateSuccessAPIResponseError(state, payload) {
        state.updateErrorAPIResp = payload;
    },
    updateManageEnrolmentFilterOptions(state, payload) {
        state.manageEnrolmentFilterOptions = payload;
        state.AddonsIdsData = payload && payload.addons;
        const user_data = localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : null;
        let filteredData = [];
        if (user_data && user_data.oemName) {
            filteredData = payload.programs && payload.programs.filter(item => item.oemName === user_data.oemName);
        } else {
            filteredData = payload.programs && payload.programs;
        }
        state.ProgramIdsData = filteredData;
    },
    updateSearchManageEnrolmentData(state, payload) {
        state.SearchManageEnrolmentData = payload;
    },
    updateProgramIds(state, payload) {
        const anyProg = state.manageEnrolmentFilterOptions.programs && state.manageEnrolmentFilterOptions.programs.filter(item => item.id === -1);
        const data = state.manageEnrolmentFilterOptions.programs && state.manageEnrolmentFilterOptions.programs.filter(item => item.oemName === payload || payload === null);
        const indx = data.findIndex(item => item.id === -1);
        if(indx === -1) {
            data.unshift(anyProg[0]);
        }
        state.ProgramIdsData = data;
    },
    updateAddonsIds(state, payload) {
        state.AddonsIdsData = payload;
    },
    updateLeadsFilterOptions(state, payload) {
        state.ViewLeadsFilterOptions = payload;
    },
    updateViewLeadsSearch(state, payload) {
        state.ViewLeadsSearchData = payload;
    },

    //LogViewer Code Starts Here
    //Get Page
    updateGetLogViewerPageData(state, payload) {
        let oldData = state.logViewerGetPageData;
        state.logViewerGetPageData = payload;
        if (oldData != null) {
            state.logViewerGetPageData.receivedDateFrom = oldData.receivedDateFrom;
            state.logViewerGetPageData.receivedDateTo = oldData.receivedDateTo;
        }
    },
    //Search
    updateLogViewerSearchData(state, payload) {
        state.logViewerSearchData = payload.response;
        state.logViewerFirstSubscribeDate = payload.firstSubscribeDate;
    },

    //Payload Lookup Code Starts Here
    //Get Page
    updateGetPageData(state, payload) {
        let oldData = state.payloadLookupGetPageData;
        state.payloadLookupGetPageData = payload;
        if (oldData != null) {
            state.payloadLookupGetPageData.receivedDateFrom = oldData.receivedDateFrom;
            state.payloadLookupGetPageData.receivedDateTo = oldData.receivedDateTo;
        }
    },
    //Search
    updatePayloadLookupSearchData(state, payload) {
        state.payloadLookupSearchData = payload;
    },
    //Payload Failure details
    updatePayloadFailureDetails(state, payload) {
        state.payloadFailureDetails = payload;
    },
    //Payload XML
    updatePayloadLookupPayloadXMLData(state, payload) {
        state.payloadLookupPayloadXMLData = payload;
    },
    //Payload Lookup Code Ends Here
    UpdateChangePasswordPermission(state, payload) {
        state.ChangePasswordPermission = payload;
    },
    UpdateChangePassword(state, payload) {
        state.ChangePasswordResp = payload;
    },
    UpdateChangePasswordError(state, payload) {
        state.ChangePasswordRespError = payload;
    },

    // Reports Mutations Call Start Here
    RedirectToCalldripSso(state, payload) {
        state.CalldripSsoAccessToken = payload;
    },
    GetRedirectToCalldripUrl(state, payload) {
        state.RedirectToCalldripUrl = payload;
    },
    UpdateGenerateReport(state, payload) {
        state.GenerateReportDetails = payload;
    },
    UpdateCheckGenerateReport(state, payload) {
        state.GenerateReportDetails[payload.key] = payload.value;
    },
    UpdateCheckShopperContactGenerateReport(state, payload) {
        state.GenerateReportDetails.commonReport[payload.key] = payload.value;
    },
    UpdateGenerateReportError(state, payload) {
        state.GenerateReportError = payload;
    },
    UpdateReportFilterDetails(state, payload) {
        state.ReportFilterDetails = payload;
    },
    UpdateReportFormDetails(state, payload) {
        state.ReportFormDetails = payload;
    },
    // Reports Mutations Call End Here

    // Manage website and packages starts here
    getEnrollmentData(state, payload){
        state.enrollmentDetails=payload;
    },
    getChangeableSubscriptions(state, payload){
        state.subscriptionDetails=payload;
    },
    getSubscriptionOptions(state, payload){
        state.enrollmentOptions = payload && payload.optionList;
        state.EULAOptions = {};
        state.EULAOptions.link = payload && payload.linkEULA;
        state.EULAOptions.enabled =payload && payload.showEULA;
    },
    postChangeSubscriptions(state, payload){
        state.ChangeSubscriptionsDetail=payload;
    },

    // Resources start here
    setAllowedProducts(state, payload){
        state.allowedProducts = payload;
    },
    setResourcesFilterOptions(state, payload){
        state.resourcesFilterOptions = payload;
    },
    // ------------ Setup Enrollment Details Code Start Here ----------
    // UpdateSetupProgramDetails(state, payload) {
    //     state.SetupProgramDetails = payload;
    // },
    SetupDealerEnrollmentData(state, payload) {
        state.DealerEnrollmentData = payload;
    },
    manageEnrolmentDownloadManagerData(state, payload) {
        return state.manageEnrolmentDownloadManagerData = payload;
    },
    SetupDealerEnrollmentDataInfo(state, payload) {
        state.DealerEnrollmentDataInfo = payload;
    },
    UpdatePostSetupDealerEnrollmentData(state, payload) {
        state.PostSetupDealerEnrollmentData = payload;
    },
    // ------------ Setup Enrollment Details Code End Here ----------

    // ----------  Lead Viewer Request Credit -----------
    updateRequestCreditReason(state, payload) {
        state.RequestCreditReason = payload;
    },

    // -------- Edit Dealer Manage Enrollment --------
    getEditDealerEnrollmentDetails(state, payload) {
        state.EnrollmentDealerDetails = payload;
    },
    getManageWebsiteProgramsFilter(state, payload) {
        state.ManageWebsiteProgramsFilter = payload;
    },
    getManageWebsitePrograms(state, payload) {
        state.ManageWebsitePrograms = payload;
    },

    getAddonSetupEnrollmentDetails(state, payload) {
        state.AddonSetupDealerDetails = payload;
    },

    getAddonCustomParameterDetails(state, payload) {
        state.AddonCustomParameterDetails = payload;
    },

    getAddonProgramConfirmationDetails(state, payload) {
        state.AddonProgramConfirmationDetails = payload;
    },

    getAddonModelDetails(state, payload) {
        state.AddonModelDetails = payload;
    },

    getAddonParamResponse(state, payload) {
        state.AddonParamResponse = payload;
    },

    getProductPricingDetail(state, payload) {
        state.ProductPricingDetails = payload;
    },

    // Compliance API call Start Here
    //---------------------------------------------------------------------------------------
    updateGenerateComplianceDataRecordState(state, payload) {
        state.ComplianceDataRecords = payload;
    },
    UpdateComplianceDataReportDetails(state, payload) {
        state.ComplianceDataReportDetails = payload;
    },
    UpdateZipCodeBulkImportResult(state, payload) {
        state.ZipCodeBulkImportResult = payload;
    },
    UpdateComplianceAggregatedReportDetails(state, payload) {
        state.ComplianceAggregatedReportDetails = payload;
    },
    UpdateNonComplaintReportDetails(state, payload) {
        state.NonComplaintReportDetails = payload;
    },
    UpdateComplianceDealerReportDetails(state, payload) {
        state.ComplianceDealerReportDetails = payload;
    },
    UpdateCrmComplianceAggregatedReportDetails(state, payload) {
        state.CrmComplianceAggregatedReportDetails = payload;
    },
    UpdateMWSearchResult(state, payload) {
        state.MWSearchResult = payload;
    },
    UpdateCrmNonComplaintReportDetails(state, payload) {
        state.CrmNonComplaintReportDetails = payload;
    },
    AffinityDealerSalesReport(state, payload) {
        state.AffinityDealerSalesReport = payload;
    },
    resetAllStates() {
        resetState();
    }
    //---------------------------------------------------------------------------------------
    // Compliance API call End Here
};
