<template>
    <div :class="[isOpen ? 'open'  : 'close']" class="modal fade modal_template opacity-1" role="dialog">
        <div class="covering_div"></div>
        <div class="modal-dialog no_transform">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <div style="pointer-events: none" class="info_heading">
                        <h4>{{ $t("app.CHECK_CONTACT_INFO") }}</h4>
                    </div>
                </div>
                <div class="modal-body">
                    <div   class="modal_form">
                      <div class="modal_heading">
                        <h4>{{ $t("app.BILLING_CONTACT_DETAILS_TITLE") }}</h4>
                      </div>
                        <form>
                            <div class="row">
                                <div class="col-xs-9 manage_form_inputs" >
                                  <form-field id="6"  error-class="form_error" :errors="errors">
                                    <p class="form_title billing_paragraph">{{ $t("app.BILLING_CONTACT_DETAILS") }}</p>
                                  </form-field>
                                </div>
                              <div class="col-xs-12 col-sm-6" v-if="disableInputFields">
                                <button @click="onClickBillingDetails" class="contact_submit edit" type="button" style="font-size: 13px;">{{ $t("app.BILLING_CONTACT_BUTTON") }}</button>
                              </div>
                              <div class="col-xs-12 col-sm-6">
                                <button @click="skipModel" class="contact_submit" type="button">{{ $t("app.SKIP") }}</button>
                              </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { bus } from '@/main';
import * as moment from "moment-timezone";

export default {
  mounted() {
    const me = this;
    bus.$on('openBillingModal', (data) => {
      me.isOpen = !me.isOpen;
      this.profileData = data;
      $("body").addClass("modal-open");
    })
  },
  computed: {
    ...mapState('dwc', ['UserDetails'])
  },
    props: [],
    name: 'modal_template',
    data() {
        return {
          profileData: {
            dealershipCode: null,
            title: null,
            contactDetailsName: null,
            emailAddress: null,
            phoneNumber: null,
            authorizedPerson: null,
            authorizedEmail: null,
            dealerWebsite: null,
            authorizedPhone: null,
            showBillingDetails: null,
            lastUpdatedDateForDealerContact: null
          },
          isOpen: false,
          disableInputFields: true,
          errors: [],
          errorsNew: [],
          canLocalEdit: true
        }
    },
    methods: {
        skipModel() {
          const me = this;
          var profileDataObject = this.mapProfileInfoToProfileDataObject();
          this.$store.dispatch('dwc/saveProfileContactDetails', profileDataObject)
            .then(() => {
              $("body").removeClass("modal-open");
              window.localStorage.setItem('profileContactModalCancelled', null);
              const me = this;
              me.isOpen = !me.isOpen;
            })
        },
        onClickBillingDetails() {
          this.skipModel();
          this.$router.push({ path: 'manageprofile'});
        },
      mapProfileInfoToProfileDataObject() {
        const me = this;
        var contactDetails = {
          email: me.profileData.emailAddress,
          name: me.profileData.contactDetailsName,
          phone: me.profileData.phoneNumber,
          title: me.profileData.title
        };
        var dwcpEnrollmentContactDetailsData = {
          dealerWebsite: me.profileData.dealerWebsite,
          authorizedPhone: me.profileData.authorizedPhone,
          authorizedPerson: me.profileData.authorizedPerson,
          authorizedEmail: me.profileData.authorizedEmail,
          lastUpdatedDateForDealerContact: moment(new Date()).format('YYYY-MM-DD')
        };
        const profileData = {
          contactDetails: contactDetails,
          dwcpEnrollmentContactDetailsData: dwcpEnrollmentContactDetailsData,
        };
        return profileData;
      }
  }
}
</script>
<style scoped>
.manage_form_inputs {
  padding-bottom: 20px;
}
.modal_heading {
  padding-bottom: 31px;
}


.modal_form h4 {
  font-size: 18px;
  color: #157c8e;
}
.modal_form p {
  font-size: 18px;
  color: #666666d1;
  font-style: normal;
  font-weight: 400;
}
 .modal_form .contact_submit {
  height: 31px;
  width: 173px;
  font-family: 'Lato', sans-serif;
  border: 1px solid #8c7b6b;
}
.modal_form .edit {
  color: #8c7b6b;
  background-color: #ffffff;
}
.modal_form .required_val {
    color: red !important;
}
.modal_form .required_validation {
    color: red !important;
    font-weight: 600;
    font-size: 14px;
    margin: 3px 0;
    position: absolute;
    padding-left: 0px;
}
.modal_form .form_error .form-control {
    border: 1px solid red !important;
    box-shadow: none;
}
.form_title {
  color: #666666d1;
  font-style: normal;
  font-weight: 400;
}
.modal_form .required_val {
  color: red !important;
}
.modal_form .required_validation {
  color: red !important;
  font-weight: 600;
  font-size: 14px;
  margin: 3px 0;
  position: absolute;
  padding-left: 0px;
}
.modal_form .form_error .form-control {
  border: 1px solid red !important;
  box-shadow: none;
}

.manage_form_inputs {
  padding-bottom: 20px;
  padding-top: 10px;
}
.modal_heading {
  padding-bottom: 31px;
}

.modal_form h4 {
  font-size: 18px;
  color: #157c8e;
}
.modal_form .contact_submit {
  height: 31px;
  width: 173px;
  border: 1px solid #8c7b6b;
}
.modal_form .edit {
  color: #8c7b6b;
  background-color: #ffffff;
}
.modal_form .required_val {
  color: red !important;
}
.modal_form .required_validation {
  color: red !important;
  font-weight: 600;
  font-size: 14px;
  margin: 3px 0;
  position: absolute;
  padding-left: 0px;
}
.modal_form .form_error .form-control {
  border: 1px solid red !important;
  box-shadow: none;
}

.modal_template .modal-dialog {
  min-width: 60%;
  max-width: calc(100vh - 153px);
}

.form_title{
  font-weight: bold;
}
.view_labels{
  font-weight: 400;
}
.form_title, .view_labels{
  color: #666666d1;
  font-style: normal;
}
.modal_form .required_val {
  color: red !important;
}
.modal_form .form_error .form-control {
  border: 1px solid red !important;
  box-shadow: none;
}
.modal_template .info_heading h4::after{
  width: 20%;
  left: 56%;
}
.modal_template .info_heading h4::before {
  width: 76%;
}

.modal_form {
  position: relative;
  left: 13%;
}
</style>
