<template>
  <span></span>
</template>

<script>
import {mapState} from "vuex";
import CmsUtil  from "../../util/CmsUtil";

export default {
  name: "CmsEditable",
  computed: {
    ...mapState('dwc', ['UserDetails']),
    UserDetails() {
      return localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : '';
    }
  },
  data() {
    return {
            changesId : 1,
            fileRequirements: "",
            selectedFile: null,
            uploadError: null,
            isConfirmPublishVisible: false,
            isConfirmCancelVisible: false,
            visibleSaveCmsButtons: {},
            isCmsEditModeOn: false,
            isCmsPreviewModeOn: false,
            isProfileContactModalOpen: false,
            isImageUploadVisible: false,
            imageBlockId: null,
	        initInlineEditorConf: {
                toolbar: 'undo redo bold underline italic alignleft aligncenter alignright alignjustify',
                menubar: false,
                hidden_input: false,
                inline: true,
                plugins: 'lists paste',
                forced_root_block: 'div',
                toolbar_sticky: true,
                formats: {
                    removeformat: { selector: '*,* *', remove: 'font,font[*]' }
                },
                paste_as_text: true
            },
	        linkInlineEditorConf: {
                toolbar: 'undo redo bold underline italic alignleft aligncenter alignright alignjustify bullist numlist link',
                menubar: false,
                hidden_input: false,
                inline: true,
                plugins: 'lists link paste',
                forced_root_block: 'div',
                toolbar_sticky: true,
                formats: {
                    removeformat: { selector: '*,* *', remove: 'font,font[*]' }
                },
                paste_as_text: true
            },
	        rawEditorConf: {
                toolbar: 'undo redo',
                menubar: false,
                hidden_input: false,
                inline: true,
                plugins: 'paste',
                toolbar_sticky: true,
                formats: {
                    removeformat: { selector: '*,* *', remove: 'font,font[*]' }
                },
                paste_as_text: true,
                setup: function (editor) {
                   editor.on("saveContent", function () {
                        var content = editor.getContent();
                        content = content.replace("<p>", "").replace("</p>", "");
                        editor.setContent(content);
                   });
                }
            },
	        imageLinkInlineEditorConf: {
                toolbar: 'undo redo bold underline italic alignleft aligncenter alignright alignjustify bullist numlist link customInsertImage',
                menubar: false,
                hidden_input: false,
                inline: true,
                plugins: 'lists link paste',
                forced_root_block: 'div',
                toolbar_sticky: true,
                formats: {
                    removeformat: { selector: '*,* *', remove: 'font,font[*]' }
                },
                paste_as_text: true,
                setup: (editor) => {
                    editor.ui.registry.addButton('customInsertImage', {
                        icon: 'image',
                        tooltip: 'Insert Image',
                        onAction: (_) => this.turnOnImageUpload(editor.id, editor.id)
                    });
                }
            },
            pageCmsData: [],
            editableLists: [],
            draftedEditableLists: [],
            contentBlocksIdsToDelete: []
    }
  },
  methods: {
        tinyMceImageUpload() {
            var editorId = this.imageBlockId;
            if (this.selectedFile && this.isImage(this.selectedFile) && editorId != null) {
                if (!this.$refs[editorId]) {
                    this.uploadMedia(this.pageCmsData[0].id, this.pageCmsData)
                } else {
                    var editor = this.$refs[editorId][0].editor;
                    if (editor) {
                        let formData = new FormData();
	                    formData.append('file', this.selectedFile);
	                    formData.append('blockId', this.unEscapeId(editorId));
                        this.$store.dispatch('dwc/uploadMedia', formData)
                        .then(response => {
			                if (response.status === 200) {
			                    var content = '<img src="' +response.data.url+ '" alt="Image">';
			                    editor.insertContent(content);
                                this.uploadError = null;
                                if (document.getElementById('cmsMediaFileInput')) {
                                    document.getElementById('cmsMediaFileInput').value = null;
                                }
                                this.turnOffImageUpload();
                            }
                        })
                        .catch(error => {
                            if (error.response && error.response.data && error.response.data.message) {
                                this.uploadError = error.response.data.message;
                            } else {
                                this.uploadError = error.response;
                            }
                        });
                    }
                }
            }
        },
        valueChanged(id){
            let old = this.pageCmsData[0].contentBlocks.filter(cb => cb.id == id)[0];
            if (old != null) {
                return old.content != this.getEditedBlockId(id);
            }
            return true;
        },
        dataToggleValue(){
            if (this.isCmsEditModeOn) {
                return "";
            } else {
                return "collapse";
            }
        },
        shouldDisplayArrow(id, direction, list) {
            if (list == null) {
                list = this.draftedEditableLists;
            }
            if (direction == 'up' && list.length > 0) {
                return list[0].id != id;
            }
            if (direction == 'down' && list.length > 0) {
                return list[list.length - 1].id != id;
            }
            return false;
        },
        getListsToShow() {
            return this.chooseListToShow(this.editableLists, this.draftedEditableLists)
        },
        chooseListToShow(mainList, draftsList) {
            if(this.isCmsPreviewModeOn || this.isCmsEditModeOn) {
                return draftsList;
            }
            return mainList;
        },
        moveDownInList(id) {
            let currentOrder = this.draftedEditableLists.filter(l => l.id == id)[0].order;
            let afterNotDeleted = this.draftedEditableLists
                .filter(l => !this.isMarkedToDelete(l.id) && l.order > currentOrder);
            if (afterNotDeleted.length > 0) {
                let toBeSwapped = afterNotDeleted[0];
                this.moveInList(id, currentOrder, afterNotDeleted, toBeSwapped);
            }
        },
        moveUpInList(id) {
            let currentOrder = this.draftedEditableLists.filter(l => l.id == id)[0].order;
            let afterNotDeleted = this.draftedEditableLists
                .filter(l => !this.isMarkedToDelete(l.id) && l.order < currentOrder);
            if (afterNotDeleted.length > 0) {
                let toBeSwapped = afterNotDeleted[afterNotDeleted.length - 1];
                this.moveInList(id, currentOrder, afterNotDeleted, toBeSwapped);
            }
        },
        moveInList(id, currentOrder, afterNotDeleted, toBeSwapped) {
            let newOrder = toBeSwapped.order;
            const toSaveMoved = CmsUtil.getDraftObjectToSaveById(this.pageCmsData[0].id, this.pageCmsData, id, null, null, newOrder);
            this.$store.dispatch('dwc/saveCmsDraft', toSaveMoved).then((result) => {
                if (!toSaveMoved.id) {
                    toSaveMoved.id = result.data.id;
                }
            });
            const toSaveSwapped = CmsUtil.getDraftObjectToSaveById(this.pageCmsData[0].id, this.pageCmsData, toBeSwapped.id, null, null, currentOrder);
            this.$store.dispatch('dwc/saveCmsDraft', toSaveSwapped).then((result) => {
                if (!toSaveSwapped.id) {
                    toSaveSwapped.id = result.data.id;
                }
            });
            toBeSwapped.order = currentOrder;
            this.draftedEditableLists.filter(l => l.id == id)[0].order = newOrder;
            this.draftedEditableLists.sort((a, b) => a.order - b.order);
        },
        addDefaultListChild(hri, content, type, order) {
            let child = {};
            child.humanReadableId = hri;
            child.content = content;
            child.type = type;
            child.blockOrder = order;
            let format = this.pageCmsData[0].contentBlocks.filter(cb => cb.humanReadableId == hri && cb.imageFormat != null);
            if (format != null && format[0] != null) {
                child.imageFormat = format[0].imageFormat;
            }
            return child;
        },
        findOrderForNewItem(place, list) {
            if (list == null) {
                list = this.draftedEditableLists;
            }
            let order = 1;
            if (place == 'begin' && list.length > 0) {
                order = list[0].order - 1;
            }
            if (place == 'end' && list.length > 0) {
                order = list[list.length - 1].order + 1;
            }
            return order;
        },
        originalExists(id) {
            return this.originalExistsInList(id, this.editableLists);
        },
        originalExistsInList(id, list) {
            return list.some(e => e.id == id);
        },
        addDefaultFaqItem(place) {
            let order = this.findOrderForNewItem(place);
            let parentHri = CmsUtil.faqQuestionHri();
            let content = 'Question';
            let type = 'Reach text';
            let childrenHris = [];
            childrenHris.push(this.addDefaultListChild(CmsUtil.faqAnswerHri(), 'Answer', 'Reach Text', order));
            this.createDrafts(parentHri, content, order, type, childrenHris);
        },
        addDefaultListItem(place, list) {
            let defaultImage = 'https://cms-media.smartdealerportal.com/9318a2a5-9fc7-4e92-abbf-c073e6d6b9d2.png'
            let defaultHeader = 'Title'
            let defaultText = 'Description'
            if (list != null) {
                defaultImage = ''
                defaultHeader = 'Logo Name'
                defaultText = 'CRM URL'
            }
            let order = this.findOrderForNewItem(place, list);
            let parentHri = 'LIST_ITEM';
            let content = '';
            let type = 'List of blocks';
            let childrenHris = [];
            childrenHris.push(this.addDefaultListChild('LIST_ITEM_IMAGE', defaultImage, 'Image', order));
            childrenHris.push(this.addDefaultListChild('LIST_ITEM_HEADER', defaultHeader, 'Reach Text', order));
            childrenHris.push(this.addDefaultListChild('LIST_ITEM_TEXT', defaultText, 'Reach Text', order));
            this.createDrafts(parentHri, content, order, type, childrenHris);
        },
        createDrafts(parentHri, content, order, type, childrenHris) {
            const savedParent = CmsUtil.createContentDraft(this.pageCmsData[0].id, this.pageCmsData, parentHri, content, order, null, type);
            this.$store.dispatch('dwc/saveCmsDraft', savedParent).then((result) => {
                if (!savedParent.id) {
                    savedParent.id = result.data.id;
                }
                let i = 0;
                let lastItem = childrenHris.length;
                childrenHris.forEach(item => {
                    const toSave = CmsUtil.createContentDraft(this.pageCmsData[0].id, this.pageCmsData, item.humanReadableId, item.content, item.blockOrder, savedParent.id, item.type, item.imageFormat);
                    this.$store.dispatch('dwc/saveCmsDraft', toSave).then((result2) => {
                        i++;
                        if (!toSave.id) {
                            toSave.id = result2.data.id;
                        }
                        if (i == lastItem) {
                            this.changesId += 1;
                        }
                    });
                });
            });
        },
        deleteListItem(id) {
            const toSave = CmsUtil.getDraftObjectToSaveById(this.pageCmsData[0].id, this.pageCmsData, id, null, 'DELETED', null);
            this.$store.dispatch('dwc/saveCmsDraft', toSave).then((result) => {
                if (!toSave.id) {
                    toSave.id = result.data.id;
                }
            });
            this.contentBlocksIdsToDelete.push(id);
        },
        restoreListItem(id) {
            const toSave = CmsUtil.getDraftObjectToSaveById(this.pageCmsData[0].id, this.pageCmsData, id, null, 'RESTORED', null);
            this.$store.dispatch('dwc/saveCmsDraft', toSave).then((result) => {
                if (!toSave.id) {
                    toSave.id = result.data.id;
                }
            });
            this.contentBlocksIdsToDelete = this.contentBlocksIdsToDelete.filter(v => v !== id);
        },
        isMarkedToDelete(id) {
            return this.contentBlocksIdsToDelete.includes(id);
        },
        publishCmsChanges() {
          new Promise(resolve => {
            this.pageCmsData
                .filter(item => item.drafts.length > 0)
                .forEach(item => {
                    let request = {};
                    request.pageId = item.id;
                    request.draftIds = item.drafts.map(d => d.id);
                    this.$store.dispatch('dwc/publishCmsChanges', request).then((res) => {
                        this.changesId += 1;
                    });
                });
            resolve();
          }).then((result) => {
                    this.isCmsEditModeOn = false;
                    this.isCmsPreviewModeOn = false;
                    this.turnOffPublishConfirmationVisibility();
                    this.visibleSaveCmsButtons = {};
                    this.selectedFile = null;
                    this.fileRequirements = "";
          });
        },
        discardCmsChanges() {
            this.pageCmsData
                .filter(item => item.drafts.length > 0)
                .forEach(item => {
                    let request = {};
                    request.pageId = item.id;
                    request.draftIds = item.drafts.map(d => d.id);
                    this.$store.dispatch('dwc/discardCmsChanges', request).then((res) => {
                        this.changesId += 1;
                    });
                });
            this.isCmsEditModeOn = false;
            this.isCmsPreviewModeOn = false;
            this.pageCmsData.forEach(item => item.drafts = []);
            this.turnOffCancelConfirmationVisibility();
            this.visibleSaveCmsButtons = {};
            this.selectedFile = null;
            this.fileRequirements = "";
            this.contentBlocksIdsToDelete = [];
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFile = file;
            }
        },
        copySimilarAsDraft(hri) {
            let parts = hri.split("_");
            parts.pop();
            let hriLike = parts.join("_")
            let template = this.pageCmsData[0].contentBlocks.filter(c => c.humanReadableId.startsWith(hriLike))[0];
            let toSave = null;
            if (template) {
                toSave = CmsUtil.createEmptyDraftFromExisting(hri, template.humanReadableId, this.pageCmsData);
                this.$store.dispatch('dwc/saveCmsDraft', toSave).then((result) => {
                    if (!toSave.id) {
                        toSave.id = result.data.id;
                        if (toSave.type == 'Image') {
                            this.imageBlockId = toSave.id;
                        }
                    }
                });
            }
            return toSave;
        },
        saveMediaDraft(pageId, pageCmsData, content) {
            const toSave = CmsUtil.getDraftObjectToSaveById(pageId, pageCmsData, this.imageBlockId, content, null, null);
            this.$store.dispatch('dwc/saveCmsDraft', toSave).then((result) => {
                if (!toSave.id) {
                    toSave.id = result.data.id;
                }
            });
            this.turnOffImageUpload();
            this.selectedFile = null;
        },
        uploadMedia(pageId, pageCmsData) {
            if (this.selectedFile && this.isImage(this.selectedFile) && this.imageBlockId != null) {
                let formData = new FormData();
	            formData.append('file', this.selectedFile);
	            formData.append('blockId', this.imageBlockId);
                this.$store.dispatch('dwc/uploadMedia', formData)
                .then(response => {
			        if (response.status === 200) {
                        this.saveMediaDraft(pageId, pageCmsData, response.data.url);
                        this.uploadError = null;
                        if (document.getElementById('cmsMediaFileInput')) {
                            document.getElementById('cmsMediaFileInput').value = null;
                        }
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.uploadError = error.response.data.message;
                    } else {
                        this.uploadError = error.response;
                    }
                });
            }
        },
        loadCmsBlockImage(blockHri) {
            return CmsUtil.getBlockContentForPage(this.pageCmsParams.pagesHri, this.pageCmsData, blockHri);
        },
        isImage(file) {
            let allowedExtensions = ["png", "jpg", "svg", "jpeg"];
            if (!allowedExtensions.includes(this.getFileExtension(file.name).toLowerCase())) {
                this.uploadError = "File must be image and have one of following extensions: " + allowedExtensions;
                return false;
            }
            return true;
        },
        escapeId(id) {
             return 'id_' + id;
        },
        unEscapeId(id) {
             return id.replace('id_', '');
        },
        getFileExtension(name) {
            return name.slice(((name.lastIndexOf(".") - 1) >>> 0) + 2);
        },
        getDraft(blockHri) {
            return CmsUtil.getDraftForPage(this.pageCmsParams.pagesHri, this.pageCmsData, blockHri);
        },
        getDraftById(id) {
            return CmsUtil.getDraftForPageById(this.pageCmsParams.pagesHri, this.pageCmsData, id);
        },
        getEditedBlockHri(blockHri) {
            let draft = this.getDraft(blockHri);
            return draft != null ? draft.content : CmsUtil.getBlockContentForPage(this.pageCmsParams.pagesHri, this.pageCmsData, blockHri);
        },
        getEditedBlockId(id) {
            let draft = this.getDraftById(id);
            let text = draft != null ? draft.content : CmsUtil.getBlockContentForPageById(this.pageCmsParams.pagesHri, this.pageCmsData, id);
            return text;
        },
        discardDraftByHri(blockHri) {
            this.pageCmsData
                .filter(item => item.id == this.pageCmsData[0].id)
                .forEach(item => {
                    let request = {};
                    request.pageId = item.id;
                    request.draftIds = item.drafts.filter(d => d.humanReadableId == blockHri).map(d => d.id);
                    this.$store.dispatch('dwc/discardCmsChanges', request);
                    item.drafts = item.drafts.filter(d => d.humanReadableId != blockHri);
                });
            this.visibleSaveCmsButtons[blockHri] = false;
            if (document.getElementById(blockHri)) {
                document.getElementById(blockHri).innerHTML = this.pageCmsData[0]
                    .contentBlocks.filter(d => d.humanReadableId == blockHri)[0].content;
            }
        },
        discardDraftById(blockId) {
            this.pageCmsData
                .filter(item => item.id == this.pageCmsData[0].id)
                .forEach(item => {
                    let request = {};
                    request.pageId = item.id;
                    request.draftIds = item.drafts.filter(d => d.contentBlockId == blockId || d.id == blockId).map(d => d.id);
                    this.$store.dispatch('dwc/discardCmsChanges', request);
                    item.drafts = item.drafts.filter(d => d.id != blockId && d.contentBlockId != blockId);
                });
            this.visibleSaveCmsButtons[blockId] = false;
            if (document.getElementById(this.escapeId(blockId))) {
                document.getElementById(this.escapeId(blockId)).innerHTML = this.pageCmsData[0]
                    .contentBlocks.filter(d => d.contentBlockId == blockId || d.id == blockId)[0].content;
            }
        },
        saveDraftById(blockId, raw) {
            let content = document.getElementById(this.escapeId(blockId)).innerHTML;
            if (raw) {
                content = content.replace("<p>", "").replace("</p>", "");
            }
            const toSave = CmsUtil.getDraftObjectToSaveById(this.pageCmsData[0].id, this.pageCmsData, blockId, content, null, null);
            this.$store.dispatch('dwc/saveCmsDraft', toSave).then((result) => {
                if (!toSave.id) {
                    toSave.id = result.data.id;
                }
            });
            this.changeSaveCmsButton(blockId, false);
        },
        turnOffImageUpload() {
            this.isImageUploadVisible = false;
            this.imageBlockId = null;
            this.fileRequirements = "";
        },
        turnOnImageUpload(id, listId) {
            if (!this.isCmsPreviewModeOn && !this.isMarkedToDelete(listId)) {
                this.isImageUploadVisible = true;
                this.imageBlockId = id;
                let cb = this.pageCmsData[0].contentBlocks.filter(c => c.id == this.unEscapeId(id));
                if (cb.length < 1) {
                    cb = this.pageCmsData[0].drafts.filter(c => c.id == this.unEscapeId(id));
                }
                if (cb.length > 0) {
                    this.fileRequirements = this.createFileRequirementsMessage(cb[0].imageFormat);
                }
            }
        },
        turnOnImageUploadByHri(hri) {
            if (!this.isCmsPreviewModeOn) {
                this.isImageUploadVisible = true;
                let cb = this.pageCmsData[0].contentBlocks.filter(c => c.humanReadableId == hri)[0];
                if (cb) {
                    this.fileRequirements = this.createFileRequirementsMessage(cb.imageFormat);
                    this.imageBlockId = cb.id;
                } else {
                    cb = this.copySimilarAsDraft(hri);
                    let imageFormat = this.pageCmsData[0].contentBlocks
                        .filter(c => c.imageFormat != null && c.imageFormat.id == cb.imageFormat)[0].imageFormat;
                    this.fileRequirements = this.createFileRequirementsMessage(imageFormat);
                }
            }
        },
        createFileRequirementsMessage(requirements) {
            if (requirements) {
                return "Uploaded file should have one of following extensions: " + requirements.allowedFormat + " " +
                "have max resolution of " + requirements.maxWidth + "x" + requirements.maxHeight + " " +
                "and Max file size no more than " + (requirements.maxFileSize / 1000) + " KB";
            }
            return "";
        },
        turnOnPublishConfirmationVisibility() {
            this.isConfirmPublishVisible = true;
        },
        turnOffPublishConfirmationVisibility() {
            this.isConfirmPublishVisible = false;
        },
        turnOnCancelConfirmationVisibility() {
            this.isConfirmCancelVisible = true;
        },
        turnOffCancelConfirmationVisibility() {
            this.isConfirmCancelVisible = false;
        },
        changeSaveCmsButton(blockHri, val) {
            this.$set(this.visibleSaveCmsButtons, blockHri, val);
        },
        turnCmsPreviewModeOn() {
            this.isCmsEditModeOn = false;
            this.isCmsPreviewModeOn = true;
        },
        turnOffCmsEditModeOn() {
            this.isCmsEditModeOn = false;
            this.isCmsPreviewModeOn = false;
        },
        turnOnCmsEditModeOn() {
            this.isCmsEditModeOn = true;
            this.isCmsPreviewModeOn = false;
        }
  },
}
</script>

<style scoped>

</style>