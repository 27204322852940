import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/home/Home.vue';
import auth from "@/auth/authService";
import routePath from '@/config/routingPath';
import {store} from "./store";
import {setDocumentDirectionPerLocale, setDocumentLang} from "@/util/i18n/document";
import {loadLocaleMessagesAsync} from "@/i18n"
import App from './App.vue'

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
        path: '/haccPage404',
        name: 'haccPage404',
        meta: {
            requiresAuth: false
        },
        component: () => import('./views/haccPage404.vue')
    },
    {
        path: '/nnanetLogout',
        name: 'nnanetLogout',
        meta: {
            requiresAuth: false
        },
        component: () => import('./views/NnanetLogout.vue')
    },
    {
        path: routePath.getRouter('login'),
        name: 'login',
        meta: {
            requiresAuth: false
        },
        component: () => import('./views/login/Login.vue')
    }, {
        path: routePath.getRouter('manageEnrollments'),
        name: 'manageEnrollments',
        meta: {
            requiresAuth: true
        },
        component: () => import ('./views/manageenrolment/manageenrolment.vue')
    }, {
        path: routePath.getRouter('websiteProgram'),
        name: 'websiteProgram',
        meta: {
            requiresAuth: true
        },
        component: () => import ('./views/mwp/ManageWebsiteProgramPage.vue')
    }, {
        path: routePath.getRouter('resources'),
        name: 'resources',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/resources/resources.vue')
    }, {
        path: routePath.getRouter('contactUs'),
        name: 'contactus',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/contact/Contactus.vue')
    }, {
        path: routePath.getRouter('logViewer'),
        name: 'logViewer',
        meta: {
            requiresAuth: true
        },
        component: () => import ('./views/logviewer/logviewer.vue')
    }, {
        path: routePath.getRouter('viewLeads'),
        name: 'viewLeads',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/viewLeads/ViewLeads.vue')
    }, {
        path: routePath.getRouter('editManageEnrollment'),
        name: 'editManageEnrollment',
        props: true,
        meta: {
            parent: 'manageEnrollments',
            requiresAuth: true
        },
        component: () => import('./views/manageenrolment/Edit/EditManageEnrollment.vue')
    }, {
        path: routePath.getRouter('billingProfile'),
        name: 'billingProfile',
        props: true,
        meta: {
            parent: 'manageEnrollments',
            requiresAuth: true
        },
        component: () => import('./views/profile/BillingProfile.vue')
    }, {
        path: routePath.getRouter('Reports'),
        name: 'reports',
        props: (route) => {
            return Object.assign({}, route.params, {reportName: route.query.q});
        },
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/reports/reports.vue')
    }, {
        path: routePath.getRouter('leadsettingsPreview'),
        name: 'leadsetting',
        meta: {
            requiresAuth: true
        },
        component: () => import ('./views/leadsettings/leadsetting.vue')
    }, {
        path: routePath.getRouter('payloadLookup'),
        name: 'payloadlookup',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/payloadlookup/payloadlookup.vue')
    }, {
        path: routePath.getRouter('setbudget'),
        name: 'setbudget',
        meta: {
            requiresAuth: true
        },
        component: () => import ('./views/setbudget/setbudget.vue')
    }, {
        path: routePath.getRouter('leadManagement'),
        name: 'leadmanagement',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/leadmanagement/LeadManagement.vue')
    } , {
        path: routePath.getRouter('smartAnalytics'),
        name: 'smartanalytics',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/smartanalytics/SmartAnalytics.vue')
    }, {
        path: routePath.getRouter('profileInformationPreview'),
        name: 'manageprofile',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/profile/ManageProfile.vue')
    }, {
        path: routePath.getRouter('dataRecording'),
        name: 'dataRecording',
        props: (route) => {
            return Object.assign({}, route.params, {auditType: 'DWCP'});
        },
        meta: {
            parent: 'reports',
            requiresAuth: true
        },
        component: () => import('./views/compliancereports/complianceReportSearch.vue')
    }, {
        path: routePath.getRouter('crmDataRecording'),
        name: 'crmDataRecording',
        props: (route) => {
            return Object.assign({}, route.params, {reportName: route.query.q});
        },
        meta: {
            parent: 'reports',
            requiresAuth: true
        },
        component: () => import('./views/compliancereports/crmComplianceReportSearch.vue')
    }, {
        path: routePath.getRouter('drsDataRecording'),
        name: 'drsDataRecording',
        props: (route) => {
            return Object.assign({}, route.params, {auditType: 'DRS'});
        },
        meta: {
            parent: 'reports',
            requiresAuth: true
        },
        component: () => import('./views/compliancereports/complianceReportSearch.vue')
    }, {
        path: routePath.getRouter("websiteProgramEdit"),
        name: 'websiteProgramEdit',
        meta: {
            parent: 'websiteProgram',
            requiresAuth: true
        },
        component: () => import ('./views/mwp/ManageWebsiteProgramSubscriptionPage.vue')
    }, {
        path: routePath.getRouter('managePackages'),
        name: 'managePackages',
        meta: {
            requiresAuth: true
        },
        component: () => import ('./views/mwp/ManageWebsiteProgramSubscriptionPage.vue')
    }, {
        path: routePath.getRouter('managesubscription'),
        name: 'managesubscription',
        meta: {
            parent: 'managePackages',
            requiresAuth: true
        },
        component: () => import ('./views/managewebsitepackages/ManageSubscriptionsComponent.vue')
    }, {
        path: routePath.getRouter('privacy'),
        name: 'privacy',
        meta: {
            parent: 'home',
            requiresAuth: true
        },
        component: () => import('./views/Privacy.vue')
    }, {
        path: routePath.getRouter('newsmartanalytics'),
        name: 'newsmartanalytics',
        meta: {
            parent: 'home',
            requiresAuth: true
        },
        component: () => import('./views/NewSmartAnalytics.vue')
    },{
        path: routePath.getRouter('shopper_contact'),
        name: 'shoppercontact',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/shoppercontact/ShopperContact.vue')
    }, {
        path: routePath.getRouter('smartleads'),
        name: 'smartleads',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/smartleads/SmartLeads.vue')
    }, {
        path: routePath.getRouter('dealerWebsiteCertification'),
        name: 'dealerwebsitecertification',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/dwc/DealerWebsiteCertification.vue')
    }, {
        path: routePath.getRouter('oemSmartAnalytics'),
        name: 'oemsmartanalytics',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/smartanalytics/SmartAnalytics.vue')
    }, {
        path: routePath.getRouter('marketPlaceServices'),
        name: 'marketPlaceServices',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/support/MarketplaceServices.vue')
    }, {
        path: routePath.getRouter('FieldSupport'),
        name: 'fieldsupport',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/support/FieldSupport.vue')
    }, {
        path: routePath.getRouter('EnterpriseServices'),
        name: 'enterpriseservices',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/support/EnterpriseServices.vue')
    },  {
        path: routePath.getRouter('concierge'),
        name: 'concierge',
        meta: {
            requiresAuth: true
        },
        component: () =>
            import('./views/concierge/Concierge.vue')
    }, {
        path: routePath.getRouter('pricecomparison'),
        name: 'pricecomparison',
        meta: {
            parent: ['dealerwebsitecertification', 'DigitalCertification'],
            requiresAuth: true
        },
        component: () => import('./views/dwc/dwcpricingcomparison/DWCPricingComparison.vue')
    }, {
        path: routePath.getRouter('drspricingcomparison'),
        name: 'drspricingcomparison',
        meta: {
            parent: 'dealerwebsitecertification',
            requiresAuth: true
        },
        component: () => import('./views/dwc/drspricingcomparison/DRSPricingComparison.vue')
    }, {
        path: routePath.getRouter('enrolment'),
        name: 'enrolment',
        meta: {
            parent: ['dealerwebsitecertification', 'DigitalCertification', 'managePackages'],
            requiresAuth: true
        },
        component: () => import('./views/dwc/enrolment/EnrollmentForm.vue')
    }, {
        path: routePath.getRouter('admindwcpenrollment'),
        name: 'admindwcpenrollment',
        meta: {
            parent: ['dealerwebsitecertification', 'DigitalCertification'],
            requiresAuth: true
        },
        component: () => import('./views/dwc/enrolment/EnrollmentForm.vue')
    }, {
        path: routePath.getRouter('video'),
        name: 'video',
        meta: {
            parent: 'pricecomparison',
            requiresAuth: true
        },
        component: () => import('./views/dwc/dwcpricingcomparison/DepDrsVideo.vue')
    }, {
        path: routePath.getRouter('payloadLookupXML'),
        name: 'payloadlookupxml',
        meta: {
            parent: 'payloadlookup',
            requiresAuth: true
        },
        component: () => import('./views/payloadlookup/payloadlookupxml.vue')
    }, {
        path: routePath.getRouter('managesubscriptionEdit'),
        name: 'managesubscriptionEdit',
        meta: {
            parent: 'managePackages',
            requiresAuth: true
        },
        component: () => import ('./views/managewebsitepackages/ManageSubscriptionsComponent.vue')
    }, {
        path: routePath.getRouter('managesubscriptionWebProgramEdit'),
        name: 'managesubscriptionWebProgramEdit',
        meta: {
            parent: 'websiteProgram',
            requiresAuth: true
        },
        component: () => import ('./views/managewebsitepackages/ManageSubscriptionsComponent.vue')
    }, {
        path: routePath.getRouter('crmComplianceCreateReport'),
        name: 'crmComplianceCreateReport',
        meta: {
            parent: 'crmDataRecording',
            requiresAuth: true
        },
        component: () => import('./views/compliancereports/crmComplianceCreateReport.vue')
    }, {
        path: routePath.getRouter('complianceCreateReport'),
        name: 'complianceCreateReport',
        meta: {
            parent: 'dataRecording',
            requiresAuth: true
        },
        component: () => import('./views/compliancereports/complianceCreateReport.vue')
    }, {
        path: routePath.getRouter('editComplianceReport'),
        name: 'editComplianceReport',
        meta: {
            parent: 'dataRecording',
            requiresAuth: true
        },
        component: () => import('./views/compliancereports/complianceEditReport.vue')
    }, {
        path: '/compliance/viewDealerReport/:reportId',
        name: 'viewComplianceDealerReport',
        meta: {
            parent: 'dataRecording',
            requiresAuth: true
        },
        component: () => import('./views/compliancereports/complianceEditReport.vue')
    }, {
        path: routePath.getRouter('leadDetailsReport'),
        name: 'leadDetailsReport',
        component: () => import('./views/dsm/LeadDetailsReport.vue')
    }, {
        path: routePath.getRouter('viewLead'),
        name: 'viewLead',
        meta: {
            parent: 'viewLeads',
            requiresAuth: true
        },
        component: () => import('./views/viewLeads/viewLead.vue')
    }, {
        path: routePath.getRouter('viewLeadResponseTime'),
        name: 'viewLeadResponseTime',
        meta: {
            parent: 'viewLeads',
            requiresAuth: true
        },
        component: () => import('./views/viewLeads/viewLeadResponseTime.vue')
    }, {
        path: routePath.getRouter('notificationsUnsubscription'),
        name: 'notificationsUnsubscription',
        meta: {
            parent: 'viewLeads',
            requiresAuth: true
        },
        component: () => import('./views/leadsettings/notificationsUnsubscription.vue')
    }, {
        path: routePath.getRouter('addonManageEnrollment'),
        name: 'addonManageEnrollment',
        props: true,
        meta: {
            parent: 'manageEnrollments',
            requiresAuth: true
        },
        component: () => import('./views/manageenrolment/Edit/AddonManageEnrollment.vue')
    }, {
        path: routePath.getRouter('howToEnroll'),
        name: 'howToEnroll',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/support/howToEnroll.vue')
    }, {
        path: routePath.getRouter('DigitalCertification'),
        name: 'DigitalCertification',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/support/DigitalCertification.vue')
    }, {
        path: routePath.getRouter('DrsServices'),
        name: 'DrsServices',
        meta: {
            requiresAuth: true
        },
        component: () => import('./views/support/DrsServices.vue')
    }, {
        path: "/:locale",
        name: 'locale',
        component: App,
        children: [
            {
                path: "",
                name: "home",
                component: Home
            },{
                path: "about",
                name: "about",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(/* webpackChunkName: "about" */ "./views/About.vue")
            }
        ],
        meta: {
            requiresAuth: true
        }
    }, {
        path: routePath.getRouter('home'),
        name: 'home',
        meta: {
            requiresAuth: true
        },
        component: Home
    },
    //otherwise redirect to home
    {
        path: '*',
        name: 'page404',
        meta: {
            requiresAuth: false
        },
        component: () => import('./views/page404.vue')
    }
    ]
});

router.routesMap = {};
for (const route of router.options.routes) {
    router.routesMap[route.name] = route;
}

router.nextRequest = function (to, next) {
    if (store.state.dwc.headerMenuData.length) {
        this.doNextRequest(store.state.dwc.headerMenuData, to, next);
    } else {
        store.dispatch('dwc/loadHeaderMenuAPI')
            .then(() => {
                this.doNextRequest(store.state.dwc.headerMenuData, to, next);
            });
    }
};

router.doNextRequest = function (headerMenuData, to, next) {
    if (to && to.path !== '/' && headerMenuData.length) {
        let toRoute = null;
        for (const route of this.options.routes) {
            const pathLower = route.path.toLowerCase();
            const toPathLower = to.path.toLowerCase();
            if (pathLower === toPathLower || (pathLower.indexOf('/:') > -1 && toPathLower.startsWith(pathLower.split('/:')[0] + '/'))) {
                toRoute = route;
                break;
            }
        }
        if (toRoute && !this.isRequestAllowed(toRoute, to, headerMenuData)) {
            router.push({ path: '/' });
            return;
        }
    }

    const firstRequest = localStorage.getItem("firstRequest");

    if (firstRequest) {
        localStorage.removeItem("firstRequest");
        this.push(JSON.parse(firstRequest));
    } else {
        next();
    }
};

router.isRequestAllowed = function (toRoute, to, menuItems) {
    if (!toRoute.meta.requiresAuth || toRoute.path === '/') {
        return true;
    }

    for (const menu of menuItems) {
        if (menu.filteredChildren.length) {
            if (this.isRequestAllowed(toRoute, to, menu.filteredChildren)) {
                return true;
            }
        } else {
            const menuRoute = routePath.getRouter(menu.url, menu.parentComponent && menu.parentComponent.name);
            if (menuRoute === toRoute.path) {
                return true;
            } else if (toRoute.path === '/reports' && to.query.q && menu.url.endsWith('/' + to.query.q)) { // reports special case - match the report name
                return true;
            } else if (toRoute.name === 'dataRecording' && menu.url.endsWith('/ComplianceAggregateReport')) { // special case for dataRecording - it can be accessed from the Aggregate report
                return true;
            } else if (toRoute.name === 'crmDataRecording' && menu.url.endsWith('/CRMComplianceAggregateReport')) { // special case for crmDataRecording - it can be accessed from the Aggregate report
                return true;
            } else if (toRoute.name === 'drsDataRecording' && menu.url.endsWith('/DRSComplianceAggregateReport')) { // special case for crmDataRecording - it can be accessed from the Aggregate report
                return true;
            }
        }
    }
    if (toRoute.meta.parent) {
        let parents = (typeof toRoute.meta.parent === 'object') ? toRoute.meta.parent : [toRoute.meta.parent];
        for (let i in parents) {
            const parentRoute = this.routesMap[parents[i]];
            if (parentRoute && this.isRequestAllowed(parentRoute, to, menuItems)) {
                return true;
            }
        }
    }
    return false;
};

router.beforeEach((to, from, next) => {
    if(!!to.params.locale && !!from.params.locale){
        if (to.params.locale === from.params.locale) {
            next();
            return
        }
        const { locale } = to.params;

        loadLocaleMessagesAsync(locale).then(() => {
            setDocumentLang(locale)
            setDocumentDirectionPerLocale(locale)
            //setDocumentTitle(i18n.t("app.title"))
        });
        next();
    }
    if (to.meta.requiresAuth) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        // let authUser = auth.isAuthenticated();
        // if (authUser && authUser.length > 30) {
        window.console.log("document.cookie:");
        window.console.log(document.cookie);
        if (auth.hasTokenAndAuth()) {
            window.console.log("Has token and auth");
            router.nextRequest(to, next);
        } else if (auth.hasAuthOnly()) {
            window.console.log("Has auth only");
            auth.implicitGetToken(to, function () {
                router.nextRequest(to, next);
            });
        } else if (auth.hasJSession()) {
            window.console.log("Has jSession");
            auth.implicitGetToken(to, function () {
                router.nextRequest(to, next);
            });
        } else {
            window.console.log("Has no jSession or token or auth");
            auth.storeFirstRequest(to);
            next({
                path: '/login'
            });
        }
    } else {
        next(); // make sure to always call next()!
    }
});

export default router;