<template>
  <div class="top_head_section" :class="[canImpersonate ? 'admin'  : '']" v-if="showMenu">

    <impersonate-toolbar v-if="canImpersonate"></impersonate-toolbar>

    <header v-bind:class="canImpersonate ? 'admin_header' : ''">
      <div class="container adjustedHeader">
        <div class="main_heading_section">
          <div class="navigation_box clearfix row">
            <div v-bind:class="'col-xs-12 col-sm-9 col-md-8 col-lg-8 no-padding headerNavBox ' + (UserDetails ? 'headerNavBox' + UserDetails.oemName : '')">
              <nav class="navbar navbar-inverse">
                <!-- Logo Section -->
                <div class="navbar-header">
                  <div class="logo_box">
                    <a class="navbar-brand" href="/" @click="redirectTo($routePath.getRouter('home'))">
                      <img v-if="trilogyLogoImg" :src=trilogyLogoImg class="img-responsive logo" alt="Logo" title="Logo">
                      <img v-else-if="UserDetails && UserDetails.oemName === 'Honda' " src="../../assets/images/png/honda/honda_logo.svg" class="img-responsive logo hondaLogo" alt="Honda Logo"
                           title="Honda Logo"/>
                      <img v-else-if="UserDetails && UserDetails.oemName === 'Acura' " src="../../assets/images/png/acura/new_acuralogo.svg" class="img-responsive logo hondaLogo" alt="Acura Logo"
                           title="Acura Logo"/>
                      <img v-else src="../../assets/images/png/autodata_small_small_cmyk.png" class="img-responsive logo" alt="Autodata Logo" title="Autodata Logo">
                    </a>
                  </div>
                  <div>
                  <button type="button" :class="UserDetails && UserDetails.oemName === 'Honda' ? 'navbar-toggle margin-90': 'navbar-toggle'" data-toggle="collapse" data-target="#myNavbar">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>
                  </div>
                </div>
                <!-- Logo Section Ends Here -->
                <div v-if="headerLogoImg && isInfiniti" class="min-infiniti-logo-wrapper pull-right hidden-sm hidden-md hidden-lg">
                    <img :src=headerLogoImg class="min-infiniti-logo pull-right img-responsive hidden-sm hidden-md hidden-lg">
                </div>
                <ul class="nav navbar-nav welcome_section pull-right hidden-sm hidden-md hidden-lg">
                  <li>
                    <div class="welcome_box">
                      <div class="dropdown" id="dropDownMenuParent">
                        <button class="welcome_btn usernameOverflow" type="button" data-toggle="dropdown" data-target="#changePassword" data-parent="#dropDownMenuParent">
                          <welcome-message/>
                        </button>
                        <button class="welcome_btn_tab usernameOverflow" type="button" data-toggle="dropdown" data-target="#changePassMobile" data-parent="#dropDownMenuParent">
                          <welcome-message/>
                        </button>
                        <button :class="UserDetails && UserDetails.oemName === 'Honda' ? 'hidden' : 'welcome_btn_mobile'" type="button" data-toggle="dropdown" data-target="#changePassMobile">
                          <welcome-message :line-break="true"/>
                        </button>
                        <ul id="changePassMobile">
                          <div class="collapse dropdown-content drop_cont dropdown-menu" v-if="passwordChangeMenuItem">
                            <div>
                              <a href="javascript:void(0);" @click="changePasswordModelShowHideShow">{{ passwordChangeMenuItem }}</a>
                            </div>
                          </div>
                        </ul>
                      </div>
                      <span class="clearfix hidden-xs"></span>
                      <img v-if="headerLogoImg && !isInfiniti" :src=headerLogoImg class="img-responsive hyundai_logo hidden-sm hidden-md hidden-lg">
                    </div>
                  </li>
                  <li class="hidden-xs">
                    <img v-if="headerLogoImg" :src=headerLogoImg class="img-responsive hyundai_logo">
                  </li>
                </ul>
                <!-- Navigation Links -->
                <div class="collapse navbar-collapse no-padding" id="myNavbar">
                  <ul v-if="!isSsoLoginOnlyAllowed" class="nav navbar-nav navigation_links">
                    <li v-for="menu in headerMenuData" :key="menu.id" :class="[menu.filteredChildren.length > 0 ? 'dropdown': '']">
                      <a v-if="menu.filteredChildren.length > 0" href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown">
                        {{ $t("app." + menu.name) }}
                        <span v-if="menu.filteredChildren.length > 0">
                                                <img src="../../assets/images/svg/chevron_down.svg" class="chevron_down hidden-xs">
                                            </span>
                      </a>
                      <a v-else @click="redirectTo($routePath.getRouter(menu.url, menu.name))" href="javascript:void(0);">
                        {{ $t("app." + menu.name) != ("app." + menu.name) ? $t("app." + menu.name) : menu.name }}
                      </a>
                      <ul v-if="menu.filteredChildren.length > 0" class="dropdown-menu">
                        <li v-for="childern in menu.filteredChildren" :key="childern.id" :class="[childern.filteredChildren.length > 0 ? 'dropdown dropdown-submenu': '']">
                          <a v-if="childern.filteredChildren.length > 0" href="javascript:void(0);" class="dropdown-toggle" @click="handelMenuClick($event)" data-toggle="dropdown">
                            {{ $t("app." + replaceVars(childern.name)) != ("app." + replaceVars(childern.name)) ? $t("app." + replaceVars(childern.name)) : replaceVars(childern.name) }}
                            <span>
                                                        <img src="../../assets/images/svg/chevron_down.svg" class="chevron_down hidden-xs">
                                                    </span>
                          </a>
                          <a v-else-if="childern.id === 46 || childern.name === 'Change Password'"  @click="changePasswordModelShowHideShow" href="javascript:void(0);">
                            {{ $t("app." + replaceVars(childern.name)) != ("app." + replaceVars(childern.name)) ? $t("app." + replaceVars(childern.name)) : replaceVars(childern.name) }}
                          </a>
                          <a v-else @click="redirectTo($routePath.getRouter(childern.url, menu.name))" :class="[menu.name === 'Products' ? 'selected-product': '']" href="javascript:void(0);"  >
                            {{ $t("app." + replaceVars(childern.name)) != ("app." + replaceVars(childern.name)) ? $t("app." + replaceVars(childern.name)) : replaceVars(childern.name) }}
                          </a>
                          <ul v-if="childern.filteredChildren.length > 0" class="dropdown-menu">
                            <li v-for="sub_childern in childern.filteredChildren" :key="sub_childern.id" >
                              <a :key="sub_childern.id"  @click="redirectTo($routePath.getRouter(sub_childern.url, menu.name))"  href="javascript:void(0);">
                                {{ $t("app." + sub_childern.name) != ("app." + sub_childern.name) ? $t("app." + sub_childern.name) : sub_childern.name }}
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li class="hidden-sm hidden-md hidden-lg" style="border: none;">
                      <a href="javascript:void(0);" @click="onLogoutClick" class="signout_btn_sm">
                        {{ $t("app.LOGOUT") }} <img src="../../assets/images/svg/signout.svg" class="signout_icon" alt="Logout" title="Logout">
                      </a>
                    </li>
                  </ul>
                </div>
                <!-- Navigation Links Ends Here -->
              </nav>
            </div>
            <!-- Left Welcome Box / Logo -->
            <div v-bind:class="'col-xs-6 col-sm-3 col-md-4 col-lg-4 no-padding hidden-xs headerUserBox ' + (UserDetails ? 'headerUserBox' + UserDetails.oemName : '')">
              <ul class="nav navbar-nav welcome_section pull-right">
                <li class="welcome_box_container">
                  <div class="welcome_box">
                    <div class="dropdown">
                      <button class="welcome_btn usernameOverflow" type="button" data-toggle="dropdown" data-target="#changePassword">
                        <welcome-message :last-login="false"/>
                      </button>
                      <button class="welcome_btn_tab dropdown-toggle" type="button" data-toggle="dropdown" data-target="#changePassword">
                        <welcome-message chevron-class="hidden-xs"/>
                      </button>
                      <ul id="changePassword">
                        <div class="collapse dropdown-content drop_cont dropdown-menu" v-if="passwordChangeMenuItem">
                          <div>
                            <a href="javascript:void(0);" @click="changePasswordModelShowHideShow">{{ passwordChangeMenuItem }}</a>
                          </div>
                        </div>
                      </ul>
                    </div>
                    <p v-if="UserDetails">
                      {{ $t("app.LastLogIn")}}:
                      {{ new Date(UserDetails.lastLoginTime).toLocaleDateString() }}
                      &nbsp;
                      {{ new Date(UserDetails.lastLoginTime).toLocaleTimeString() }}
                    </p>
                    <span class="clearfix hidden-xs"></span>
                    <!-- <p>Last Log in : 02/25/2019&nbsp;&nbsp;12:45 PM PST</p> -->
                    <img v-if="headerLogoImg" :src=headerLogoImg class="img-responsive hyundai_logo hidden-sm hidden-md hidden-lg">

                  </div>
                </li>
                <!-- Logo -->
                <li class="hidden-xs">
                  <img v-if="headerLogoImg" :src=headerLogoImg class="img-responsive hyundai_logo">
                </li>
                <!-- Logo Ends Here -->
              </ul>
            </div>
          </div>
          <!-- Left Welcome Box / Logo Ends Here -->
        </div>
        <change-password :isOpen="isOpen" @changePasswordModelShowHide="changePasswordModelShowHide"></change-password>
      </div>
    </header>

    <section v-if="canImpersonate" class="admin_heading_space"></section>
    <section v-if="!canImpersonate && isInfiniti" class="infiniti_heading_space"></section>
  </div>

</template>

<script>
import changePassword from '@/components/ChangePassword.vue'
import impersonateUserToolbar from '@/components/header/ImpersonateUserToolbar.vue'
import {mapState} from 'vuex'
import auth from "../../auth/authService";
import welcomeMessage from "@/components/header/welcomeMessage";
import {replaceOemCustomLabelPlaceholders} from "@/config/Config";
import { userLocale } from "../../util/i18n/supported-locales";
import  { loadLocaleMessagesAsync } from "@/i18n";

export default {
  components: {
    'change-password': changePassword,
    'impersonate-toolbar': impersonateUserToolbar,
    'welcome-message': welcomeMessage
  },
  props: {
    showMenu: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  created() {
    if (this.API_Call_Flag) {
      this.$store.dispatch('dwc/loadUserDetails');
      this.$store.dispatch('dwc/loadHeaderMenuAPI');
      this.$store.commit('dwc/updateAPICallFlag', false);
      let locale = userLocale();
      if (locale != null) {
        loadLocaleMessagesAsync(locale);
      }
    }

    auth.startListenCookieChange();
  },
  computed: {
    ...mapState('dwc', ['headerMenuData', 'UserDetails', 'API_Call_Flag']),
    isSsoLoginOnlyAllowed() {
      this.openChangePasswordModalRedirectedActionFromLMS();
      return this.UserDetails ? this.UserDetails.ssoLoginOnlyAllowed : false;
    },
    trilogyLogoImg() {
      if (!this.UserDetails || this.UserDetails.oemName === 'Honda' || !this.UserDetails.trilogyLogo) {
        return null;
      }
      return "data:image/png;base64," + this.UserDetails.trilogyLogo;
    },
    headerLogoImg() {
      if (!this.UserDetails || this.UserDetails.oemName === 'Honda' || !this.UserDetails.headerLogo) {
        return null;
      }
      return "data:image/png;base64," + this.UserDetails.headerLogo;
    },
    isInfiniti(){
     return this.UserDetails && this.UserDetails.oemName === "INFINITI";
    },
    canImpersonate() {
      if (!this.showMenu) {
        return false;
      }
      if (this.UserDetails) {
        return this.UserDetails.belongsToCmsEditorGroup ||
            (this.UserDetails.impersonator && this.UserDetails.impersonator.belongsToCmsEditorGroup) ||
            this.UserDetails.role === 'admin' ||
            (this.UserDetails.impersonator && this.UserDetails.impersonator.role === 'admin') ||
            this.UserDetails.role === 'Dealer Group' ||
            (this.UserDetails.impersonator && this.UserDetails.impersonator.role === 'Dealer Group');
      }
      return false;
    },
    passwordChangeMenuItem() {
      if (this.headerMenuData) {
        for (let i = 0; i < this.headerMenuData.length; i++) {
          const menu = this.headerMenuData[i];
          if (menu.name === 'Settings') {
            for (let j = 0; j < menu.filteredChildren.length; j++) {
              const child = menu.filteredChildren[j];
              if (child.name === 'Change Password') {
                return child.name;
              }
            }
          }
        }
      }
      return '';
    }
  },
  methods: {
    replaceVars(body) {
      if (this.UserDetails) {
        return replaceOemCustomLabelPlaceholders(this.UserDetails, body);
      }
      return body;
    },
    changePasswordModelShowHide() {
      const me = this;
      me.isOpen = !me.isOpen;
    },
    changePasswordModelShowHideShow() {
      const me = this;
      me.$store.dispatch('dwc/checkChangePasswordPermission')
          .then(() => {
            me.isOpen = !me.isOpen;
          })
    },
    handelMenuClick(event) {
      event.preventDefault();
      event.stopPropagation();
      var elems = document.querySelectorAll(".dropdown-submenu");
      [].forEach.call(elems, function (el) {
        el.classList.remove("open");
      });
      let parentN = event.target.parentNode;
      var imgMatches = event.target.matches ? event.target.matches('img') : event.target.msMatchesSelector('img');
      var spanMatches = event.target.matches ? event.target.matches('span') : event.target.msMatchesSelector('span');
      if (imgMatches) {
        parentN = event.target.parentNode.parentNode.parentNode;
      } else if (spanMatches) {
        parentN = event.target.parentNode.parentNode;
      }
      if (!parentN.className.includes('open')) {
        parentN.classList.add("open");
      } else {
        parentN.classList.remove("open");
      }
    },
    onLogoutClick() {
      window.console.log('Calling logOut from onLogoutClick header.vue');
      auth.logOut();
    },
    redirectTo(path) {
      if (path.startsWith("http")) {
        window.open(path);
        return;
      }
      if (this.$route.path !== path) {
        this.$router.push({path})
          .catch(error => {
            window.console.info(error);
          });
      }
    },
    openChangePasswordModalRedirectedActionFromLMS() {
      if (location.href.indexOf("changePassword") > 0) {
        this.changePasswordModelShowHideShow();
        window.history.pushState({}, document.title, "/" + '');
      }
    }
  },
  mounted() {
  },
  watch: {
    UserDetails: function (user) {
      if (user.oemName && user.language) {
        if (user.language === 'ENGLISH') {
            this.$store.dispatch('dwc/loadProductData', user.oemName);
        } else {
            this.$store.dispatch('dwc/loadProductData', user.oemName + "_" + user.language);
        }
        this.$store.dispatch('dwc/loadDWCData', user.oemName)
      }
    },
  }
}
</script>
<style scoped>
@media (-ms-high-contrast: none) {
  .welcome_box_container {
    width: 260px;
  }
}

@media (min-width: 1200px) {
  .headerNavBoxHonda {
    width: 850px;
  }

  .headerUserBoxHonda {
    width: 290px;
  }

  .hondaLogo {
    width: 226px !important;
    padding-top: 10px;
  }
}

@media (max-width: 465px) and (min-width: 320px) {
  .hondaLogo {
    width: 190px !important;
  }
}

.navbar-nav > li > .dropdown-menu li ul {
  position: relative;
  left: auto;
}

</style>
